// REACT IMPORTS
import React from 'react';

// APP IMPORTS
import LogoBubble from './LogoBubble/LogoBubble';
import SearchBubble from './SearchBubble/SearchBubble';
import AddBubble from './AddBubble/AddBubble';
import MessagesBubble from './MessagesBubble/MessagesBubble';
import NotificationBubble from './NotificationBubble/NotificationBubble';
import AccountBubble from "./AccountBubble/AccountBubble";

// MUI IMPORTS
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

export default function AppBarDefault() {
	return (
		<>
			<AppBar elevation={0} position="sticky" sx={{ left: 0, top: 0, margin: 0, padding: 0, width: '100%', backgroundColor: 'transparent' }} >
				<Toolbar sx={{ margin: 0, padding: 0, height: '100%', backgroundColor: 'transparent' }} >

					{ /* Left icons */ }
					<Box sx={{ margin: 0, padding: 0, width: '100%', height: '100%', display: 'flex' }}>
						<LogoBubble />
						<SearchBubble />
					</Box>

					{ /*This is the space in the center between left, and right*/ }            
					<Box sx={{ flexGrow: 1 }} />

					{ /* Menu icons on the right side of the appbar */ }                    
					<Box sx={{ margin: 0, padding: 0, display: 'flex' }}>
						<AddBubble />
						<MessagesBubble />
						<NotificationBubble />
						<AccountBubble />
					</Box>

				</Toolbar>
			</AppBar>
		</>
	);
}
