// REACT IMPORTS
import React from 'react';
import { useState, useContext } from 'react';

// APP IMPORTS
import { Search } from '../../../routes/Search'; // move this to search bubble

// MUI IMPORTS
import IconButton from '@mui/material/IconButton';
import { Menu, Paper } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";

// DESO IMPORTS
import { DeSoIdentityContext } from 'react-deso-protocol';

function LogoBubble() {
	const { currentUser, isLoading } = useContext(DeSoIdentityContext);
    const [searchWindow, setSearchWindow] = React.useState(null); 
    const openSearch = Boolean(searchWindow);

	const openSearchWindow = (event) => {
		setSearchWindow(event.currentTarget);
	};

	const closeSearchWindow = () => {
		setSearchWindow(null);
	};

	{/* this could probably ust be put into search bubble */}
	const OpenSearch = () => {
		return (
			<Paper sx={{ backgroundColor: 'transparent', zIndex: 2000,  position: 'fixed', margin: 0, padding: 0, top: 0, left: 0, border: 0, width: 325 }} >
				<Search />
			</Paper>
		);
	}

	const renderMenu = (
		<>
			{currentUser && (
				<Menu
					anchorEl={searchWindow}
					id="account-menu"
					open={openSearch}
					onClose={closeSearchWindow}
					//onClick={handleClose}
					PaperProps={{
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&::before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					<Search />
				</Menu>
			)}
		</>
	);

	return (
		<>
			<IconButton
				size="large"
				edge="start"
				color="inherit"
				aria-label="open drawer"
				onClick={openSearchWindow}
				sx={{ m: 0, backgroundColor: 'none', '&:hover': { backgroundColor: 'none', }, }}
			>
				<SearchIcon />
			</IconButton>

			{searchWindow && OpenSearch()}
		</>
	);
}

export default LogoBubble