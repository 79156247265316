// REACT IMPORTS
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

// STYLESHEETS
import './Feed.css';

// APP IMPORTS
import Post from '../Post/Post';

// MUI IMPORTS
import TweetBox from '../TweetBox/TweetBox';

// DESO IMPORTS
import { DeSoIdentityContext } from 'react-deso-protocol';
import { getPostsStateless } from 'deso-protocol';

const Feed = () => {
	const { currentUser, isLoading } = useContext(DeSoIdentityContext);
	const [userProfile, setUserProfile] = useState([]);
	const { username } = useParams();
	const [postData, setPostData] = useState([]);

	useEffect(() => {
		getLatestPostFeed();
	}, [isLoading])

    // LATEST POSTS FEED
    const getLatestPostFeed = async () => {
		const result = await getPostsStateless({
			ReaderPublicKeyBase58Check: currentUser && currentUser.PublicKeyBase58Check || (''),
			ResponseLimit: 50
		});
		setPostData(result.PostsFound);
    }

	return (
		<div className="feed">
			{currentUser && (
				<React.Fragment>
					<TweetBox />
					<br />
				</React.Fragment>
			)}
			{(postData && (
				postData.map((item) => (
					<Post postObject={item} />
				))
			))}
		</div>
	);
}

export default Feed;