// REACT IMPORTS
import React from 'react';

// APP IMPORTS
import AppBarDefault from '../components/AppBarDefault/AppBarDefault';
import Feed from '../components/Feed/Feed';

export const Home = () => {
    return (
        <>
            <AppBarDefault />
            <Feed />
        </>
    )
}
