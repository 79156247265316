import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import Feed from '../components/Feed/Feed'
import Widgets from '../components/Widgets/Widgets'

import Button from '@mui/material/Button';
import { identity } from "deso-protocol";

export const Notifications = () => {
    return (
        <div className="app">

        </div>
    )
}