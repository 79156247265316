// REACT IMPORTS
import React from 'react';

// MUI IMPORTS
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

function AddBubble() {
	return (
		<IconButton
			size="large"
			edge="start"
			color="inherit"
			aria-label="open drawer"
			sx={{ m: 0, backgroundColor: 'none', '&:hover': { backgroundColor: 'none', }, }}
		>
			<AddIcon />
		</IconButton>
	);
}

export default AddBubble