// REACT IMPORTS
import React from 'react';

// ROUTES
import { Root } from './routes/Root';
import { Home } from './routes/Home';
import { Search } from './routes/Search'
import { Notifications } from './routes/Notifications';
import { Messages } from './routes/Messages';
import { Profile } from './routes/Profile';

// DESO IMPORTS
import { DeSoIdentityProvider } from 'react-deso-protocol';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
	{
    	path: "/",
		element: <Root />,
		children: [
			{
				path: "/",
				element: <Home />
			},
			{
				path: "/:username",
				element: <Profile />
			},
			{
				path: "/Search",
				element: <Search />
			},
			{
				path: "/notifications",
				element: <Notifications />
			},
			{
				path: "/messages",
				element: <Messages />
			},
			{
				path: "/profile",
				element: <Profile />
			},
		],
	},
]);

function App() {
	return (
		<DeSoIdentityProvider>
			<RouterProvider router={router} />
		</DeSoIdentityProvider>
	);
}

export default App;
