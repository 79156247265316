// REACT IMPORTS
import React from 'react';

// STYLESHEETS
import './TweetBox.css';

// MUI IMPORTS
import { Avatar, Button, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { styled } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ImageIcon from '@mui/icons-material/Image';

// DESO IMPORTS
import { identity } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import { isValidBS58PublicKey, getSingleProfile } from 'deso-protocol'
import { useState, useEffect, useContext } from 'react'

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
	duration: theme.transitions.duration.shortest,
	}),
}));

function TweetBox() {
	const { currentUser, isLoading } = useContext(DeSoIdentityContext);
	const [ userProfile, setUserProfile ] = useState([]);

	useEffect(() => {
		getProfileUser();
	}, [isLoading])

	// Get the profile of the user we are searching for
	const getProfileUser = async () => {
		if (currentUser) {
		const result = await getSingleProfile({ PublicKeyBase58Check: (currentUser && currentUser.PublicKeyBase58Check), NoErrorOnMissing: true })
		setUserProfile(result.Profile);
		}
	}

	const [formats, setFormats] = React.useState(() => []);

	const handleFormat = (event, newFormats) => {
	setFormats(newFormats);
	};

	return (
		<React.Fragment>
		{!isLoading && (
			<Card elevation={10} 
				sx={{ borderRadius: 2, color: 'rgb(192, 192, 192)', backgroundColor: 'rgba(0,0,0,0.24)', margin: '0px auto', maxWidth: 625, minWidth: 275 }}
			>
			<CardHeader
				sx={{ display: 'flex', alignItems: 'flex-start', color: 'white', input: 'white', input: { color: 'white' } }}
				avatar={
					<Avatar alt="Remy Sharp" src={'https://node.deso.org/api/v0/get-single-profile-picture/' + (currentUser && currentUser.PublicKeyBase58Check)} />
				}
				title={
					<>
					<TextField 
						className="tweetBox__inputBox"
						id="tweetBox__inputBox"
						label="What's on your mind?"
						placeholder='Whats on your mind?'
						variant="filled"
						InputLabelProps={{ style: { color: 'white' } }}
						inputProps={{ style: { color: 'rgb(192, 192, 192)' } }}
						fullWidth
						multiline
					/>

					<CardActions sx={{ m:0, p:0, mt: 1 }} disableSpacing>
						<ToggleButtonGroup
							value={formats}
							onChange={handleFormat}
							aria-label="text formatting"
						>
		
					<ToggleButton sx={{ border: 0 }} value="bold" aria-label="bold">
						<FormatBoldIcon sx={{ color: 'white' }} />
					</ToggleButton>
					<ToggleButton sx={{ border: 0 }} value="italic" aria-label="italic">
						<FormatItalicIcon sx={{ color: 'white' }} />
					</ToggleButton>
					<ToggleButton sx={{ border: 0 }} value="underlined" aria-label="underlined">
						<FormatUnderlinedIcon sx={{ color: 'white' }} />
					</ToggleButton>
					</ToggleButtonGroup>

						<ExpandMore>
						<Button variant="contained">Post</Button>
						</ExpandMore>
					</CardActions>
					</>
				}
			/>

		</Card>
		)}
		</React.Fragment>
	)
}

export default TweetBox;