import React from 'react'
import './Inbox.css'

function Inbox() {
    return (
        <React.Fragment>
            <div style={{ flex: .4 }}>
                Messages
            </div>
        </React.Fragment>
    )
}

export default Inbox;