// React imports
import React from "react";
import { styled, alpha } from '@mui/material/styles';

// MUI imports
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { ArrowBack } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import CloseIcon from '@mui/icons-material/Close';

const Searcha = styled('div')(({ theme }) => ({
    position: 'relative',
    //borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    //width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        //width: 'auto',
    },
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    height: '100%', m: 0, p: 0,
    //width: '100%',
    //maxWidth: '200',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('xs')]: {
        width: '100%',
        //width: '6ch',
        '&:focus': {
          //width: '15ch',
          //width: '100%',
        },
      },
    },
  }));

  function generate(element) {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }


//export const Search = () => {
 export function Search({ back }) {
  const [dense, setDense] = React.useState(true);
  const [secondary, setSecondary] = React.useState(false);

    return (
        <Box elevation={0} sx={{ margin: 0, padding: 0, width: '100%' }}>

            <AppBar elevation={0} position="sticky" sx={{ left: 0, top: 0, margin: 0, padding: 0, width: '100%', backgroundColor: 'transparent' }} >

                <Toolbar elevation={0} sx={{ margin: 0, padding: 0, height: '100%', backgroundColor: 'transparent' }} >

                    { /* Left icons */ }
                    <Box elevation={0} sx={{ margin: 0, padding: 0, width: '100%', height: '100%', display: 'flex' }}>

                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={back}
                            sx={{ margin: 0, backgroundColor: 'none', '&:hover': { backgroundColor: '#5398DD', }, }}
                        >
                            <ArrowBack />
                        </IconButton>

                        <Searcha
                            sx={{
                                backgroundColor: 'rgba(0,0,0,0.24)',
                                width: '100%',
                                margin: 0,
                                padding: 0,
                                justifyContent: 'left',
                                borderRadius: '24px'
                            }}
                        >
                            {(
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                            )}
                        </Searcha>

                    </Box>

                </Toolbar>
            </AppBar>

            <List dense={dense} sx={{ backgroundColor: 'white', borderRadius: 2, margin: 0, padding: 0, width: '100%' }}>
              {generate(
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="freedom2film"
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>,
              )}
            </List>

        </Box>
    )
}
