// REACT IMPORTS
import React from "react";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';

// STYLESHEETS
import "./Post.css";

// MUI IMPORTS
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";

import { Avatar } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import CommentIcon from "@mui/icons-material/Comment";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DiamondIcon from "@mui/icons-material/Diamond";
import LanguageIcon from '@mui/icons-material/Language';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import { identity } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";

// This converts "/n" to <br /> so that the post body text displays
// propperly.
export const escapedNewLineToLineBreakTag = (string) => {
    return string.split('\n').map((item, index) => {
        return (index === 0) ? <Linkify>{item}</Linkify> : [<br key={index} />, <Linkify>{item}</Linkify>]
    })
}

// This converts URL's in the post body text to links
const Linkify = ({ children }) => {
    const isUrl = word => {
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
        return word.match(urlPattern)
    }

    const isUserTagUrl = word => {
        const urlPattern = /(@[a-zA-Z0-9])\w+$/gm;
        return word.match(urlPattern)
    }

    const isHashTagUrl = word => {
        const urlPattern = /(#.)\w+$/g;
        return word.match(urlPattern)
    }

    const addMarkup = word => {
        return isUrl(word) ?
            `<a href="${word}">${word}</a>` :
            word
    }

    const addUserTagMarkup = word => {
        return isUserTagUrl(word) ?
            `<a href="${word}">${word}</a>` :
            word
    }

    const addHashTagMarkup = word => {
        return isHashTagUrl(word) ?
            `<a href="/tags/${word.substr(1)}">${word}</a>` :
            word
    }

    const words = children.split(' ')
    var formatedWords = words.map((w, i) => addMarkup(w)); // post body links
    formatedWords = formatedWords.map((w, i) => addUserTagMarkup(w)); // user tag links
    formatedWords = formatedWords.map((w, i) => addHashTagMarkup(w)); // hashtag links
    const html = formatedWords.join(' ')
    return (<span dangerouslySetInnerHTML={{ __html: html }} />)
}

function Post({ postObject }) {
    const { currentUser, isLoading } = useContext(DeSoIdentityContext);
    const navigate = useNavigate();
    const clickMe = (parameter) => (event) => {
        navigate(parameter);
      };

      
    var options = {hour12: true, hour: '2-digit', minute: '2-digit', year: '2-digit', month: 'numeric', day: 'numeric' };
    return (
        <>
            {console.log(postObject)}
            {/* This is our post object */}
            <Card elevation={10} 
                sx={{ borderRadius: 2, color: 'rgb(192, 192, 192)', backgroundColor: 'rgba(0,0,0,0.24)', margin: '0px auto', maxWidth: 625, minWidth: 275 }}
            >

                {/* This is the header of each post */}
                <CardHeader
                    avatar={
                        <Avatar src={`https://node.deso.org/api/v0/get-single-profile-picture/${postObject.PosterPublicKeyBase58Check}`} />
                    }
                    action={
                        <>
                            <Tooltip title={postObject.PostExtraData.app && postObject.PostExtraData.app || "Diamond"}>
                            <IconButton sx={{ color: 'rgb(192, 192, 192)' }} aria-label="settings">
                                {postObject.PostExtraData.app ? (
                                    (postObject.PostExtraData.app == "Diamond") && <DiamondIcon /> ||
                                    (postObject.PostExtraData.app == "DeSocialWorld") && <LanguageIcon /> ||
                                    (postObject.PostExtraData.app == "Desofy") && <FavoriteIcon /> ||
                                    <QuestionMarkIcon />
                                ) : (
                                    <DiamondIcon />
                                )}
                            </IconButton>
                            </Tooltip>

                            <IconButton sx={{ color: 'rgb(192, 192, 192)' }} aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                        </>
                    }
                    title={
                        <Typography sx={{ cursor: 'pointer', fontSize: 14, color: 'rgb(192, 192, 192)' }}
                            onClick={
                                clickMe("@" + (postObject.ProfileEntryResponse && postObject.ProfileEntryResponse.Username) || (postObject && postObject.PosterPublicKeyBase58Check)) || ''
                            }
                        >
                            {postObject.ProfileEntryResponse ? postObject.ProfileEntryResponse.Username : postObject.PosterPublicKeyBase58Check}
                            {postObject.ProfileEntryResponse && postObject.ProfileEntryResponse.IsVerified && (
                                <VerifiedIcon fontSize="14" sx={"color: rgb(0,0,225)"} />
                            )}
                        </Typography>
                    }
                    subheader={
                        <Typography sx={{ fontSize: 14, color: 'rgb(192, 192, 192)' }}>{new Date(postObject.TimestampNanos/1000000).toLocaleDateString("en-US", options)}</Typography>
                    }
                />

                {/* If post has images, show them all */}
                {postObject.ImageURLs && postObject.ImageURLs.map((i) => (
                    <CardMedia
                        component="img"
                        height="300"
                        image={i}
                        alt="Paella dish"
                    />
                ))}

                {postObject.PostExtraData.EmbedVideoURL && (
                    <CardMedia
                        component="iframe"
                        height="300"
                        src={postObject.PostExtraData.EmbedVideoURL}
                        alt="Paella dish"
                        sx={{ border: 0 }}
                    />
                )}

                {postObject.VideoURLs && (
                    <CardMedia
                        component="iframe"
                        height="400"
                        src={postObject.VideoURLs}
                        alt="Paella dish"
                        sx={{ border: 0 }}
                    />
                )}

                <CardContent>
                    {
                        postObject.Body && (escapedNewLineToLineBreakTag(postObject.Body)) ||
                        postObject.RepostedPostEntryResponse && (escapedNewLineToLineBreakTag(postObject.RepostedPostEntryResponse.Body))
                    }
                </CardContent>

                <CardActions disableSpacing>
                {postObject.IsNFT && (
                        <>
                            {postObject.NumNFTCopiesForSale + " of " + postObject.NumNFTCopies + " for sale"}
                            {postObject.NumNFTCopiesForSale > 0 ? (
                                <Button variant="contained" sx={{ marginLeft: 'auto' }} >$Buy NFT</Button>
                            ) : (
                                <Button disabled variant="contained" sx={{ marginLeft: 'auto' }} >Buy NFT</Button>
                            )}

                        </>
                    )}
                </CardActions>

                <CardActions>
                    <IconButton sx={{ flex: 1, color: "rgb(192, 192, 192)"}} aria-label="add to favorites">
                        <CommentIcon sx={{ mr: 1 }} />
                        {
                            postObject.RepostedPostEntryResponse && (postObject.RepostedPostEntryResponse.CommentCount) ||
                            postObject.CommentCount && (postObject.CommentCount)
                        }
                    </IconButton>
                    
                    <IconButton sx={{ flex: 1, color: "rgb(192, 192, 192)"}} aria-label="add to favorites">
                        {
                            postObject.PostEntryReaderState && postObject.PostEntryReaderState.RepostedByReader && <RepeatIcon sx={{ color: 'green', mr: 1 }} /> ||
                            <RepeatIcon sx={{ mr: 1 }} />
                        }
                        {
                            postObject.RepostedPostEntryResponse && (postObject.RepostedPostEntryResponse.RepostCount) ||
                            postObject.RepostCount && (postObject.RepostCount)
                        }
                    </IconButton>

                    <IconButton sx={{ flex: 1, color: "rgb(192, 192, 192)"}} aria-label="add to favorites">
                        {
                            postObject.PostEntryReaderState && postObject.PostEntryReaderState.LikedByReader && <FavoriteIcon sx={{ color: 'red', mr: 1}} /> ||
                            <FavoriteIcon sx={{ mr: 1 }} />
                        }
                        {
                            postObject.RepostedPostEntryResponse && (postObject.RepostedPostEntryResponse.LikeCount) ||
                            postObject.LikeCount && (postObject.LikeCount)
                        }
                    </IconButton>

                    <IconButton sx={{ flex: 1, color: "rgb(192, 192, 192)"}} aria-label="add to favorites">
                        {
                            postObject.PostEntryReaderState && postObject.PostEntryReaderState.LikedByReader && <DiamondIcon sx={{ color: 'primary.main', mr: 1 }} /> ||
                            <DiamondIcon sx={{ mr: 1 }} />
                        }
                        {
                            postObject.RepostedPostEntryResponse && (postObject.RepostedPostEntryResponse.DiamondCount) ||
                            postObject.DiamondCount && (postObject.DiamondCount)
                        }
                    </IconButton>
                </CardActions>
            </Card>
            <br/>
        </>
    )
}

export default Post;