// REACT IMPORTS
import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI IMPORTS
import IconButton from '@mui/material/IconButton';
import TwitterIcon from "@mui/icons-material/Twitter";

function LogoBubble() {
	const navigate = useNavigate();
	const goHome = () => { navigate("/"); }

	return (
		<IconButton
			size="large"
			edge="start"
			color="inherit"
			aria-label="open drawer"
			onClick={goHome}
			sx={{ m: 0, backgroundColor: 'none', '&:hover': { backgroundColor: 'none', }, }}
		>
			<TwitterIcon />
		</IconButton>
	);
}

export default LogoBubble