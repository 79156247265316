// REACT IMPORTS
import React from 'react';
import { useState, useContext } from 'react';

// MUI IMPORTS
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail';

function MessagesBubble() {
	const [messagesCount, setMessagesCount] = useState(0);

	return (
		<IconButton
			size="large"
			edge="start"
			color="inherit"
			aria-label="open drawer"
			sx={{ m: 0, backgroundColor: 'none', '&:hover': { backgroundColor: 'none', }, }}
		>
			<Badge badgeContent={messagesCount} color="error">
				<MailIcon />
			</Badge>
		</IconButton>
	);
}

export default MessagesBubble