// REACT IMPORTS
import React from 'react';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

// DESO IMPORTS
import { configure } from 'deso-protocol';
import { DeSoIdentityContext } from 'react-deso-protocol';

// DESO CONFIGURATION STUFF
configure({
	spendingLimitOptions: {
		GlobalDESOLimit: 10000000, // 0.01 DESO
		TransactionCountLimitMap: {
			SUBMIT_POST: "UNLIMITED",
		},
	},
});

export const Root = () => {
	const { currentUser, isLoading } = useContext(DeSoIdentityContext);

	return (
		<React.Fragment>
			<div role="main" className="main-content">
				{isLoading ? <div>Loading...</div> : <Outlet />}
			</div>
		</React.Fragment>
	);
};
