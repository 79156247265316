// REACT IMPORTS
import React from 'react';
import { useState, useEffect, useContext } from 'react';

// APP IMPORTS
import Post from '../Post/Post';

// DESO IMPORTS
import { DeSoIdentityContext } from 'react-deso-protocol';
import { getPostsForUser } from 'deso-protocol';

function UserFeed({ userObject }) {
	const { currentUser, isLoading } = useContext(DeSoIdentityContext);
	const [postData, setPostData] = useState([]);

	useEffect(() => {
		getUserPostFeed();
	}, [currentUser, userObject])

	// USER POSTS FEED
	const getUserPostFeed = async () => {
		if (userObject && userObject.PublicKeyBase58Check) {
			const result = await getPostsForUser({
				ReaderPublicKeyBase58Check: currentUser && currentUser.PublicKeyBase58Check || (''),
				PublicKeyBase58Check: userObject.PublicKeyBase58Check,
				NumToFetch: 50,
				NoErrorOnMissing: true
			});
			result && setPostData(result.Posts);
		}
	}

	return (
		<div className="feed">
			{(postData && (
				postData.map((item) => (
					<Post postObject={item} />
				))
			))}
		</div>
	)
}

export default UserFeed;