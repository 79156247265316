// REACT IMPORTS
import React from 'react'
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';

// CUSTOM IMPORTS
import AppBarDefault from '../components/AppBarDefault/AppBarDefault'
import UserFeed from '../components/Feed/UserFeed'

// MUI IMPORTS
import { Paper, Box, Card, CardContent, CardMedia } from '@mui/material'
import { Avatar, Skeleton, Chip } from '@mui/material'

// DESO IMPORTS
import { isValidBS58PublicKey, getSingleProfile } from 'deso-protocol'
import { DeSoIdentityContext } from "react-deso-protocol";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: 0,
}));

export const Profile = () => {
    const { username } = useParams();
    const [userProfile, setUserProfile] = useState([]);
    const { currentUser, isLoading } = useContext(DeSoIdentityContext);

    useEffect(() => {
        getProfileUser();
    }, [isLoading])

    // Make sure our username or public key starts with a "@"
    if (username.substr(0, 1) !== "@") {
        return;
    }

    // Get the profile of the user we are searching for
    const getProfileUser = async () => {
        if (isValidBS58PublicKey(username.substr(1))) {
            const result = await getSingleProfile({ PublicKeyBase58Check: username.substr(1), NoErrorOnMissing: true } )
            result && setUserProfile(result.Profile);
        } else {
            const result = await getSingleProfile({ Username: username.substr(1), NoErrorOnMissing: true } )
            result && setUserProfile(result.Profile);
        }
    }

    return (
        <>
        {!isLoading && (
        <Box sx={{ margin: 0 }} >
            <AppBarDefault />
            
            <Card elevation={10} 
            sx={{ borderRadius: 2, color: 'rgb(192, 192, 192)', backgroundColor: 'rgba(0,0,0,0.24)', margin: '0px auto', maxWidth: 625, minWidth: 275 }}
        >

            {(userProfile.ExtraData && (
                <CardMedia
                        component="img"
                        height="250"
                        image={(userProfile.ExtraData && (userProfile.ExtraData.FeaturedImageURL))}
                        alt=""
                    />
            ))}

            <CardContent>
            <div className="userProfile__header">

                {!userProfile.PublicKeyBase58Check ? (
                    <Skeleton variant="circular"width={40} height={40} />
                ) : (
                    <Avatar sx={{ height: '100px', width: '100px' }} src={`https://node.deso.org/api/v0/get-single-profile-picture/${userProfile.PublicKeyBase58Check}`} />
                )}

                <Chip sx={{ color: 'white' }} label={ userProfile.Username && (userProfile.Username) || userProfile.PublicKeyBase58Check } />
                <br />
                <Chip sx={{ color: 'white' }} label={userProfile && userProfile.PublicKeyBase58Check} />

            </div>
            </CardContent>
        </Card> <br/>

                {/* New, Hot, Followers, Profile */}
                <UserFeed userObject={userProfile}/>

        </Box>
        )}
        </>
    )
}
