// REACT IMPORTS
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// STYLESHEETS
import './AccountBubble.css';

// MUI Imports
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ListItemText from '@mui/material/ListItemText';

// DESO IMPORTS
import { identity } from 'deso-protocol';
import { DeSoIdentityContext } from 'react-deso-protocol';
import { getSingleProfile } from 'deso-protocol';

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			//animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}));

function AccountBubble() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const { currentUser, isLoading } = useContext(DeSoIdentityContext);
	const [userProfile, setUserProfile] = useState([]);

	useEffect(() => {
		getProfileUser();
	}, [isLoading])

	// Get the profile of the user we are searching for
	const getProfileUser = async () => {
		if (currentUser) {
			const result = await getSingleProfile({ PublicKeyBase58Check: currentUser.PublicKeyBase58Check, NoErrorOnMissing: true })
			setUserProfile(result.Profile);
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const renderMenu = (
		<>
		{currentUser && (
		<Menu
			anchorEl={anchorEl}
			id="account-menu"
			open={open}
			onClose={handleClose}
			//onClick={handleClose}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					mt: 1.5,
					'& .MuiAvatar-root': {
					width: 32,
					height: 32,
					ml: -0.5,
					mr: 1,
					},
					'&::before': {
					content: '""',
					display: 'block',
					position: 'absolute',
					top: 0,
					right: 14,
					width: 10,
					height: 10,
					bgcolor: 'background.paper',
					transform: 'translateY(-50%) rotate(45deg)',
					zIndex: 0,
					},
				},
			}}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
		>
			<MenuItem componrnt={Link} to={"/@" + userProfile.Username}>
				{currentUser ? (
					<Avatar alt="Remy Sharp" src={'https://node.deso.org/api/v0/get-single-profile-picture/' + (currentUser && currentUser.PublicKeyBase58Check) } />
				) : (
					<Avatar onClick={() => identity.login()} />
				)}
				<ListItemText primary={userProfile.Username} secondary={userProfile.DESOBalanceNanos && ("🪙" + " " + userProfile && userProfile.DESOBalanceNanos / 1e9)} />
			</MenuItem>

			<Divider />

			<MenuItem>
				<Avatar src="https://images.deso.org/3ac8585a8b1b4b0163ae9c99669186ed0edc84edf320977f24a026a58c9b8987.webp" /> 0xScriptless
			</MenuItem>
			
			<MenuItem onClick={() => { identity.login() }}>
				<ListItemIcon>
					<PersonAdd fontSize="small" />
				</ListItemIcon>
				Add another account
			</MenuItem>

			<Divider />

			<MenuItem>
				<ListItemIcon>
					<Settings fontSize="small" />
				</ListItemIcon>
				Settings
			</MenuItem>
			
			<MenuItem onClick={() => { identity.logout() }}>
				<ListItemIcon>
					<Logout fontSize="small" />
				</ListItemIcon>
				Logout
			</MenuItem>

		</Menu>
		)}
		</>
	);

	return (
		<React.Fragment>
			{(isLoading || !currentUser ? (
				<IconButton
					onClick={() => identity.login()}
					size="large"
					edge="start"
					color="inherit"
					aria-label="open drawer"
					sx={{ m: 0, backgroundColor: 'none', '&:hover': { backgroundColor: 'none', }, }}
				>
					<Avatar alt={"Click to login or create an account."}/>
				</IconButton>
			) : (
				<IconButton
					onClick={handleClick}
					size="large"
					edge="start"
					color="inherit"
					aria-label="open drawer"
					sx={{ m: 0, backgroundColor: 'none', '&:hover': { backgroundColor: 'none', }, }}
				>
					<StyledBadge
						overlap="circular"
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						variant="dot"
					>
						<Avatar alt={currentUser.Username || currentUser.PublicKeyBase58Check} src={ `https://node.deso.org/api/v0/get-single-profile-picture/${currentUser.PublicKeyBase58Check}` } />
					</StyledBadge>
				</IconButton>
			))}
		{renderMenu}
		</React.Fragment>
	);
}

export default AccountBubble