// REACT IMPORTS
import React from 'react';
import { useState, useEffect, useContext } from 'react';

// STYLESHEETS
import './NotificationBubble.css';

// MUI IMPORTS
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';

// DESO IMPORTS
import { DeSoIdentityContext } from 'react-deso-protocol';
import { getUnreadNotificationsCount } from 'deso-protocol';

function NotificationBubble() {
	const { currentUser, isLoading } = useContext(DeSoIdentityContext);
	const [notificationCount, setNotificationCount] = useState(0);

	useEffect(() => {
		getNotificationsCount();
	}, [isLoading])

	const getNotificationsCount = async () => {
		if (currentUser) {
			const result = await getUnreadNotificationsCount({
				PublicKeyBase58Check: currentUser.PublicKeyBase58Check
			});
			setNotificationCount(result.NotificationsCount);
		}
	}

	return (
		<IconButton
			size="large"
			edge="start"
			color="inherit"
			aria-label="open drawer"
			sx={{ m: 0, backgroundColor: 'none', '&:hover': { backgroundColor: 'none', }, }}
		>
			<Badge badgeContent={notificationCount} color="error">
				<NotificationsIcon />
			</Badge>
        </IconButton>
    );
}

export default NotificationBubble